<template>
  <div class="app-container">
    <HeaderComponent />
    <div class="form" >
      <el-form ref="form" :inline="true" :model="form">
          <el-form-item label="予約者メール：" class="input">
            <el-input v-model="form.email" maxlength="60" clearable></el-input>
          </el-form-item>
          <!-- <el-form-item label="予約者電話：" class="input">
            <el-input v-model="form.user_tel" maxlength="11" clearable></el-input>
          </el-form-item> -->

          <el-form-item label="代表者カナ：" class="input">
            <el-input v-model="form.delegate_kn" maxlength="60" clearable></el-input>
          </el-form-item>
          <el-form-item label="代表者電話：" class="input">
            <el-input v-model="form.tel" maxlength="11" clearable></el-input>
          </el-form-item>

          <el-form-item label="予約番号：" class="input">
            <el-input v-model="form.rsv_no" maxlength="60" clearable></el-input>
          </el-form-item>
          <el-form-item label="予約日：">
            <el-date-picker
              v-model="form.rsv_date"
              type="date"
              placeholder=" "
              clearable
              value-format="YYYY/MM/DD"
              format="YYYY/MM/DD"
            />
          </el-form-item>
          <el-form-item label="旅行開始日：">
            <el-date-picker
              v-model="form.start_date"
              type="date"
              placeholder=" "
              clearable
              value-format="YYYY/MM/DD"
              format="YYYY/MM/DD"
            />
          </el-form-item>
          <el-form-item label="旅行終了日：">
            <el-date-picker
              v-model="form.end_date"
              type="date"
              placeholder=" "
              clearable
              value-format="YYYY/MM/DD"
              format="YYYY/MM/DD"
            />
          </el-form-item>
          <el-form-item label="予約状況：" >
            <el-select v-model="form.reservation_status" placeholder=" " clearable>
              <el-option
                v-for="item in rsvStatusList"
                :key="item.code_value"
                :label="item.code_nm"
                :value="item.code_value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="入金状況：" >
            <el-select v-model="form.paysts" placeholder=" " clearable>
              <el-option
                v-for="item in paystsList"
                :key="item.code_value"
                :label="item.code_nm"
                :value="item.code_value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="発券状況：" >
            <el-select v-model="form.ticketing_status" placeholder=" " clearable>
              <el-option
                v-for="item in ticketingStatusList"
                :key="item.code_value"
                :label="item.code_nm"
                :value="item.code_value"
              />
            </el-select>
          </el-form-item>
      </el-form>

      <div class="div_right">
        <el-button type="primary"  @click="submitForm()">検索</el-button>
      </div>

      <el-row type="flex" justify="end">
        <el-pagination
          :page-size="page_size"
          background
          layout="total,  prev, pager, next, jumper"
          :total="total"
          :current-page="pageNumber"
          @current-change="handleCurrentChange"
        />
      </el-row>
      <el-table
        :data="list"
        ref="table"
        highlight-current-row
        row-key="key"
        @row-click="onRowClick"
      >
        <el-table-column prop="rsv_no" label="予約番号" align="center" width="120" />
        <el-table-column prop="cust_user.email" label="予約者メール" show-overflow-tooltip  align="center"  width="180"/>
        <!-- <el-table-column prop="cust_user.user_tel" label="予約者電話"  align="center"  width="120"/> -->
        <el-table-column prop="mdelegate.delegate_kn" label="代表者カナ"  align="center"  width="180"/>
        <el-table-column prop="mdelegate.tel" label="代表者電話"  align="center"  width="120"/>
        <el-table-column prop="rsv_date" label="予約日"  align="center"  width="160"/>
        <el-table-column prop="reservation_status_value" label="予約状況" show-overflow-tooltip align="center" width="140" />
        <el-table-column prop="start_end_date" label="旅行日程" align="center" width="180"/>
        <el-table-column prop="mpayment.paysts_value"  label="入金状況"  align="center" width="80"/>
        <el-table-column prop="ticketing_status_value" align="center" label="発券状況" width="80"/>
        <el-table-column prop="mpayment.total_price_str" align="right" label="合計価格" width="90"/>
        <el-table-column prop="biko" show-overflow-tooltip label="メモ" />
      </el-table>
    </div>

    <FotterComponent />
  </div>
</template>

<script>
import HeaderComponent from '@/components/Header/index'
import FotterComponent from '@/components/Fotter/index'
import { getUniversal } from '@/api/universal'
import { getList } from '@/api/rs110m'
import { toThousandFilter } from '@/utils/formFormat'

export default {
  name: '',
  components: {
    HeaderComponent,
    FotterComponent
  },
  data() {
    return {
      form: {
        email: '',
        // 代表者
        delegate_kn: '',
        // 代表電話
        tel: '',
        rsv_no: '',
        rsv_date: '',
        start_date: '',
        end_date: '',
        reservation_status: '',
        paysts: '',
        ticketing_status: ''
      },
      formCopy: {},
      rsvStatusList: [],
      ticketingStatusList: [],
      paystsList: [],

      list: [],
      total: 0,
      page_size: 10,
      pageNumber: 1
    }
  },
  created() {
    this.getCodes()
    this.formCopy = this.form
    this.getList()
  },
  methods: {
    async getCodes() {
      var res = await getUniversal(['16', '17', '07'])
      this.rsvStatusList = res.data.code_16
      this.ticketingStatusList = res.data.code_17
      this.paystsList = res.data.code_07
    },
    submitForm() {
      this.pageNumber = 1
      this.formCopy = this.form
      this.getList()
    },
    async getList() {
      var res = await getList(this.pageNumber, this.form)
      this.list = res.data.list
      this.total = res.data.total
      if (this.list.length === 0) {
        this.$message({
          type: 'info',
          message: this.message.I003
        })
      }
      this.list.forEach(item => {
        item.start_end_date = item.start_date + ' - ' + item.end_date
        item.mpayment.total_price_str = toThousandFilter(parseInt(item.mpayment.total_price))
      })
    },
    handleCurrentChange(pageNumber) {
      this.form = this.formCopy
      this.pageNumber = pageNumber
      this.getList()
    },
    onRowClick(row) {
      this.$router.push({ path: '/rs111m', query: { rsv_id: row.rsv_id }})
    }
  }
}
</script>

<style lang="scss" scoped>

  .form{
    margin: 10px 40px;
  }
</style>
