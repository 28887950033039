import request from '@/utils/request'

export function getList(page, data) {
  return request({
    url: '/app/reserve/',
    method: 'get',
    params: {
      page: page,
      data: data
    }
  })
}
