import request from '@/utils/request'

export function getUniversal(data) {
  return request({
    url: '/app/universal/',
    method: 'get',
    params: {
      code_id: data
    }
  })
}
